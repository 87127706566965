import styled from "styled-components";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import Hero from "../../hero";

export const StyledBackgroundSection = styled.div`
  background: linear-gradient(110.57deg, rgba(228, 255, 248, 0.1) -2.17%, #e4fff8 99.02%);
  width: 1440px;
  height: 403px;
  position: relative;
  width: 100%;
  z-index: 4;
`;

export const HeaderWrapper = styled.header`
  padding: 142px 0 0 0;
  position: relative;
`;

export const HeaderTextGroup = styled.div`
  margin: 0;
  max-width: 510px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  ${({ theme }) => theme.mq.lg`
    align-items: flex-start;
  `}
`;

export const DownloadButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 64px auto;

  ${({ theme }) => theme.mq.lg`
  margin: 24px auto;
    justify-content: flex-start;
  `}
`;

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 64px 16px 64px;
`;

export const CTA = styled(OutboundLink)`
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  margin: 0 8px;

  ${({ theme }) => theme.mq.lg`
    margin: 0 16px 0 0;  
  `}
`;
