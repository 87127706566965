import React from "react";
import { Box, Heading, Text } from "rebass/styled-components";
import styled, { css } from "styled-components";

const textMixin = css`
  a {
    &,
    &:visited,
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }

    text-decoration: none;
  }

  span {
    font-weight: bold;
  }

  ol,
  ul {
    margin-top: 1rem;
  }
`;

const listMixin = css`
  padding-left: 2rem;
  > li {
    color: rgba(33, 15, 96, 1);
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 2rem;

    ${({ theme }) => theme.mq.md`
      font-size: 20px;
    `}

    ${textMixin};
  }
`;

export const Content = styled(Box)`
  padding: 70px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #57636c26;
  }
`;

export const Section = styled(Box)`
  &:not(:last-child) {
    margin-bottom: 6rem;
  }
`;

export const TextContent = styled.div`
  padding: 10px 15px;
  background: rgba(29, 207, 159, 0.2);
  * {
    color: rgba(33, 15, 96, 1);
  }
  ${({ theme }) => theme.mq.md`
      padding: 40px 70px;
      background: rgba(29, 207, 159, .2);
  `}
`;

export const H1 = styled(props => <Heading mb="1.5rem" textAlign="left" {...props} />)``;

export const H2 = styled(props => <Text mb="1.5rem" as="h3" variant="inlineHeader" textAlign="left" {...props} />)``;

export const P = styled(props => <Text variant="body" as="p" textAlign="left" {...props} />)`
  ${textMixin}
  font-size: 12px;
  ${({ theme }) => theme.mq.md`
    font-size: 20px;
  `}
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const UL = styled.ul`
  list-style: ${({ listStyle }) => listStyle};
  padding-left: ${props => (props.pl ? props.pl : "0px")};
  ${listMixin}
`;

export const OL = styled.ol`
  list-style: ${({ listStyle }) => listStyle};

  ${listMixin}
`;

/** MERCHANT AGREEMENT SPECIFIC STYLES  */

export const UnderlineWrapper = styled.div`
  display: flex;

  span {
    padding-right: 15px;
  }
`;

export const Underline = styled.div`
  border-bottom: 1px solid #071435;
  width: 80%;
  padding: 0 0 0 10px;
`;

export const LinkText = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`;

export const UnderlineShort = styled.div`
  border-bottom: 1px solid #071435;
  width: 100%;
  padding: 0 0 0 10px;
`;

export const WhereasParagraph = styled.div``;

UL.defaultProps = {
  listStyle: "disc",
};

OL.defaultProps = {
  listStyle: "decimal",
};
