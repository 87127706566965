import React from "react";
import { Box, Flex } from "rebass/styled-components";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";

import { Container } from "../components/global";

import Navigation from "../components/common/navigation/navigation";
import Header from "../components/sections/terms-privacy/header";
import Footer from "../components/sections/footer";
import { P, OL, UnderlineWrapper, Underline, UnderlineShort, Content, Section } from "../styles/TCandPrivacyStyles";

const formatDay = day => {
  if (day == 11 || day == 12) return `${day}th`;
  switch (`${day}`.substr(-1)) {
    case "1":
      return `${day}st`;
    case "2":
      return `${day}nd`;
    case "3":
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

const getDate = () => {
  const date = new Date().toDateString();
  const [month, day, year] = date.split(" ").splice(1);
  return `${formatDay(day)} of ${month} of ${year}`;
};

const MerchantAgreement = () => (
  <Layout>
    <SEO title="Merchant Agreement Policy" />
    <Navigation background="white" />
    <Header {...pageData.headerData} />
    <Container>
      <Content>
        <Section>
          <P>The INDEPENDENT RETAIL MERCHANT agreement (“Agreement”) is made and effective this {getDate()} BETWEEN</P>

          <P>
            <span>OPAY DIGITAL SERVICES LIMITED</span>, a company registered in Nigeria and having its registered office
            at Alexander House, Otunba Jobi Fele Way, Ikeja. Lagos, Nigeria. (hereinafter referred to as “
            <span>OPAY</span>” which expression shall where the context so admits include its successors in title and
            assigns)
          </P>

          <P>
            <UnderlineWrapper>
              <span>AND</span> <Underline />
            </UnderlineWrapper>
            <br />a Company registered in Nigeria and having its registered office
          </P>
          <br />
          <P>
            <UnderlineWrapper>
              <span>AT</span> <Underline />
            </UnderlineWrapper>
            <br /> (herein after referred to as the “<span>Merchant</span>”, which expression shall where the context so
            admits include its successors in title and assigns)
          </P>
          <P>
            OPay and MERCHANT may be hereinafter collectively referred to as “Parties” and individually as “the Party”.
          </P>
          <P>
            <span>WHEREAS</span>
            <OL>
              <li>OPay operates a payment platform known as OPay.</li>
              <li>
                The Merchant has requested to act as an independent retail merchant of OPay´s payment service platform
                with the non-exclusive right to promote the OPay products and services to customers.
              </li>
              <li>
                OPay has now decided by this agreement to appoint the Merchant non-exclusively for the promotion and
                sale of OPay and the Merchant has agreed to accept the appointment with the understanding that the
                Merchant will receive a commission for its promotion and sale of OPay as set out hereof.
              </li>
              <li>
                The Merchant is in full compliance with the Mobile Money Guidelines as well as the regulations regarding
                the merchant banking and merchant banking relationships applicable in Nigeria.
              </li>
            </OL>
          </P>
          <P>
            <span>THE PARTIES ARE AGREED AS FOLLOWS:</span>
            <br />
            <OL>
              <br />
              <li>
                The Merchant is hereby appointed as an merchant for the sale of the OPay products and services within
                the territory of the Federal Republic of Nigeria (hereinafter referred to as “Territory”) with the
                intent that the Merchant shall be entitled to its commission in respect of every product and service
                sold within theTerritory.
              </li>
              <li>This Agreement shall commence on the date of signature and continue until terminated.</li>
              <li>
                The Merchant shall assist customers to perform OPay transactions, including but not limited to the
                following:
                <OL listStyle="lower-alpha">
                  <li>Signing up for a new account.</li>
                  <li>Depositing/cash into an existing account.</li>
                  <li>Withdrawal/ cash out from an existing account.</li>
                  <li>Sending cash to another party; processing bill payments.</li>
                  <li>Purchasing mobile phone airtime for self or to third parties</li>
                  <li>Any other transaction that may be integrated on the OPay platform from time to time.</li>
                </OL>
              </li>
              <li>
                In performing the services stated in clause 3 above, the Merchant agrees not to charge customers any fee
                in variance with the OPay transaction pricing policy approved and provided by OPay. The Merchant agrees
                to strictly abide by the OPay product pricing policy and commission schedule defined and published by
                OPay from time to time. This pricing policy will be publicly available and consistent across the OPay
                operating network. The Merchant shall, clearly display the charges of the OPay products.
              </li>
              <li>
                The Merchant shall complete all the registration processes of each of the OPay customers, and the
                commission set out in clause 21. below shall be calculated considering the completed transactions and
                registrations, in the understanding that in the event the registration or transaction process is not
                completed the Merchant shall not receive commission for the said operation.
              </li>
              <li>
                The Merchant shall comply with the Know Your Client (“KYC”) policies and applicable regulations as may
                be the case and shall keep complete files of every transaction and registration performed by the
                Merchant. As per this clause, the Merchant herein obliges himself to deliver, upon notice, to OPay the
                complete files as set above. If the Merchant fails to do so, OPay can terminate this agreement without
                previous notice and the Merchant shall be held liable for any action that may derived herein.
              </li>
              <li>
                The Merchant shall comply with the Anti-Money Laundry (“AML”) policies and applicable regulations as the
                case may be. If the Merchant fails to comply with this clause, OPay can terminate this agreement without
                previous notice and the Merchant shall be held liable for any action that may derived herein.
              </li>
              <li>
                Merchants shall not have any informal or secret dealings with any OPay staff for the purpose of any form
                of profit sharing arrangement. If any Merchant is found to be dealing personally with any OPay staff,
                the Merchant's account shall be suspended with immediate effect and OPay shall reserve the right to
                determine whether or not to terminate this agreement.
              </li>
              <li>
                The Merchant shall at its sole cost and expense obtain all permits and licenses necessary in connection
                with its performance of this Agreement, and will comply with the Guidelines on Mobile Money Services
                applicable in Nigeria and/or all applicable laws, rules and regulations in the performance of this
                Agreement.
              </li>
              <li>
                The Merchant agrees to devote its best efforts to market and promote the use of OPay by customers within
                the Territory and to conform to the best practice in the industry or as may be mutually agreed by the
                parties.
              </li>
              <li>
                The Merchant shall not use the OPay logo, OPay or OPay trademark, or any other trademark of OPay in a
                manner or format other than as provided by OPay. The Merchant acknowledges that the OPay and OPay logo,
                trademarks etc. are the sole property of OPay and the Merchant does not by reason of this agreement
                acquire any right to the same.
              </li>
              <li>
                The Merchant agrees that any breach of the policy by the Merchant will result in sanctions and could
                lead to termination of this Agreement.
              </li>
              <li>
                OPay reserves the right to review the transaction pricing policy from time to time and may revise the
                terms seven (7) days’ prior notice to the Merchant.
              </li>
              <li>
                The Merchant agree that accepting this Agreement, it is bound by the product pricing policy, AML and KYC
                policies as well as the commission schedule as well as any future revisions or amendments of same.
              </li>
              <li>
                By executing this agreement, the Merchant warrant that you have read and understood how your data would
                be managed in line with OPay Privacy Policy and you signify your agreement to OPay Privacy Policy. OPay
                reserves the right to change or amend this Privacy Policy at any time. If we make any material changes
                to this Privacy Policy, the revised Policy will be posted on the website and application.
              </li>
              <li>
                The Merchant shall upon payment of a registration or signup fee have a minimum of one OPay merchant
                account and assigned to it by OPay which shall correspond to any money deposited into the Merchant's
                account.
              </li>
              <li>
                The Merchant shall upon deposit of an agreed sum with any OPay designated banks, receive an equivalent
                e-float (electronic value) loaded into its OPay Merchant account on OPay. All transactions performed by
                the OPay Merchant will be credited or debited, as appropriate, from the balance in their associated OPay
                Merchant account. All monies deposited by the OPay Merchant into the OPay system (including commissions
                or fees earned) remains the property of the OPay Merchant and will be available for withdrawal (less any
                pending charges) in full should the OPay Merchant choose to do so.
              </li>
              <li>
                OPay shall maintain an accurate accounting of transactions on the Merchant's account which records will
                be available to the Merchant upon demand.
              </li>
              <li>
                The Merchant agrees that:
                <OL listStyle="lower-alpha">
                  <li>All the transactions shall be in accordance with the OPay system and recording procedures.</li>
                  <li>
                    All transactions for which the Merchant accepts cash from customers will result in a debit of
                    equivalent e-float from the Merchant's OPay merchant account.
                  </li>
                  <li>
                    All transactions for which the Merchant gives out cash to customers will result in a debit of
                    equivalent e-float into the Merchant's OPay merchant account.
                  </li>
                  <li>
                    All monies owed by OPay to the Merchant will be credited into the Merchant's OPay merchant account.
                  </li>
                  <li>
                    Where possible, monies owed by the Merchant to OPay will be debited from the Merchant's OPay
                    Merchant account. Otherwise, OPay shall require use of another convenient method of payment by the
                    OPay Merchant.
                  </li>
                  <li>
                    All chargebacks which satisfactory evidence are not provided by the Merchants will be debited from
                    the Merchants OPay wallet or OPay Account.
                  </li>
                </OL>
              </li>
              <li>
                The Merchant, during the term of this agreement shall receive a commission from the promotion and sale
                of the product at the end of every month in accordance with the agreed Commissions schedules, provided
                that OPay reserves the right to withhold payment to the Merchant if the Merchant breaches the terms of
                this Agreement.
              </li>
              <li>
                The Merchant shall receive a commission of 5% (Five percent) of the net revenue of total transactions of
                the merchants which were introduced to OPay on a monthly basis. The commission shall be paid to the
                Merchant within the 30 (thirty) days following the end of the month from which the commissions are
                calculated.
              </li>
              <li>
                The Merchant acknowledges that it shall be subject to inspection of the entirety of its operations by
                representatives of OPay at any time and shall, on demand by the OPay representatives, access to and the
                right to examine the financial books of accounts and vouchers of the Merchant in order to ascertain
                whether the business of Merchant is being conducted in a professional manner.
              </li>
              <li>
                Confidential Information means non-public information that a OPay or its affiliates (“disclosing Party”)
                discloses to the Merchant which is designated as being ‘proprietary’ or ‘confidential’ or which by its
                nature or the circumstances reasonably ought to be treated as confidential. Confidential Information
                includes OPay’s software and prototypes and information relating to OPay’s business affairs, including
                business methods, marketing strategies, pricing, competitor information, product development strategies,
                and financial results. Confidential Information does not include information which (a) is known by the
                Merchant, free of any obligation to keep it confidential; (b) is at the time of disclosure, or
                thereafter becomes, publicly available through no wrongful act of the Merchant; (c) is independently
                developed by the Merchant, without relying on or referring to the Confidential Information of OPay; or
                (d) is approved for release by prior written authorization of OPay.
              </li>
              <li>
                The Merchant shall not disclose OPay’s Confidential Information to any third party or use Confidential
                Information for any purpose other than for the proper fulfillment of this Agreement. The Merchant
                undertakes to safeguard the Confidential Information of OPay with the same degree of care as it would
                apply to its own Confidential Information and, in any case, with no less than reasonable care. Such
                obligations will survive the expiration of this Agreement for a period of five (5) years.
              </li>
              <li>
                The agency created by this agreement may be mutually terminated by either party by written notice of at
                least three (3) months mailed or delivered to the last known address of the other party return receipt
                acknowledged. In addition, OPay may terminate this Agreement without notification if, in its sole
                judgment, OPay’s reputation or public safety is affected in an adverse manner by actions of the
                Merchant.
              </li>
              <li>
                The Merchant agrees to indemnify and hold OPay harmless for any loss, claim, damage, or liability of any
                kind, to the extent caused by the negligence or wilful misconduct of the Merchant or its affiliates,
                employees and associates, arising out of actions by the Merchant, or any party acting on its behalf or
                under its authorization, in performance of its activities under this Agreement.
              </li>
              <li>
                The Merchant's relation to OPay under this Agreement is that of an independent merchant. Nothing in this
                Agreement is intended or should be construed to create a partnership, joint venture, or
                employer-employee relationship between OPay and Merchant. The Merchant will take no position with
                respect to or on any tax return or application for benefits, or in any proceeding directly or indirectly
                involving OPay, that is inconsistent with the Merchant being an independent merchant (and not an
                employee) of OPay. The Merchant is not the merchant of OPay and is not authorized and must not represent
                to any third party that Merchant is authorized, to make any commitment or otherwise act on behalf of
                OPay.
              </li>
              <li>
                This Agreement constitutes the entire understanding of the parties hereto and the failure of either
                Party to enforce at any time or for any period of time any provisions of this Agreement shall not be
                construed as a waiver of such provision or of the right of such Party thereafter to enforce each and
                every such provision of this Agreement
              </li>
              <li>
                If any dispute, claim, controversy or difference of any kind whatsoever shall arise between the parties
                in connection with or arising out of this Agreement, or breach thereof (the “Dispute”), the Parties
                shall endeavor to resolve such Dispute by way of discussions and negotiations in the first instance
                within twenty (20) days from the date upon which one Party has given written notice of the Dispute to
                the other Party, by discussions between or among the parties (“Discussion Period”). If the Dispute
                cannot be settled within the Discussion Period, then the matter may be referred to arbitration in
                accordance with section below, unless the Parties otherwise agree in writing.
                <br />
                <br />
                Any Dispute between the Parties hereto arising out of or in connection with the terms of this Agreement
                shall be referred to arbitration to be conducted pursuant to Rules of Arbitration of the International
                Chamber of Commerce (the “ICC Rules”).
                <br />
                <br />
                The arbitration committee shall consist of three (3) arbitrators. Each Party shall appoint one
                arbitrator within five (5) days of notice to commence arbitral proceedings and the two arbitrators shall
                appoint the third arbitrator. If either Party does not appoint its own arbitrator such arbitrator shall
                be appointed in accordance with the ICC Rules. The seat of the arbitration shall be [Lagos, Nigeria] and
                the language of any arbitration shall be English.
                <br />
                <br />
                This shall not preclude any Party from obtaining interim relief on an urgent basis from a court of
                competent jurisdiction pending the decision of the arbitrators.
              </li>
            </OL>
          </P>
          <P>
            <P>I have read and understood the terms and conditions and wish to open an Merchant account with OPay.</P>
            <br />
            <P>
              IN WITNESS WHEREOF the parties have caused their common seal to be hereunto affixed the day and year first
              above written.
            </P>
            <br />
            <br />
            <br />
            <Flex flexWrap="wrap" justifyContent="space-between">
              <Box mb="4rem">
                <P>THE COMMON SEAL of the within-named</P>
                <P>
                  <span>OPay Digital Services Limited</span>
                </P>
                <P>Was hereunto affixed</P>
                <P>In the presence of:</P>
                <P>Name:</P>
                <P>Date:</P>
                <br />

                <P>
                  <UnderlineShort />
                  <br />
                  Authorized Signatory & Date
                </P>
              </Box>

              <Box mb="4rem">
                <P>THE COMMON SEAL of the within-named</P>
                <P>&nbsp;</P>
                <P>Was hereunto affixed</P>
                <P>In the presence of:</P>
                <P>Name:</P>
                <P>Date:</P>
                <br />

                <P>
                  <UnderlineShort />
                  <br />
                  Authorized Signatory & Date
                </P>
              </Box>
            </Flex>
          </P>
        </Section>
      </Content>
    </Container>
    <Footer />
  </Layout>
);

export default MerchantAgreement;

const pageData = {
  headerData: {
    captionText: "Merchant Agreement",
    subTitleText: "26th October 2020",
  },
};
